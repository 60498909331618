import React from 'react';

import Seo from '../components/seo';
import Banner from '../components/Careers/Banner';
import Form from '../components/Careers/Form';

const Careers = () => (
  <>
    <Seo title="Career | Zenn HRB" />
    <Banner />
    <Form />
  </>
);

export default Careers;
