import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div`
  color: ${$.color.base};

  p {
    padding: 0 ${$.layout().padding1}px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: ${$.layout().margin4}px;

  & > * {
    width: 100%;
    height: 100%;
  }

  ${$.device.desktop} {
    width: 100%;
  }

  ${$.device.tablet} {
    width: calc(100% + ${$.layout().padding4 * 2}px);
    left: -${$.layout().padding4}px;
    height: 320px;
  }

  ${$.device.mobile} {
    width: calc(100% + ${$.layout().padding4 * 2}px);
    left: -${$.layout().padding4}px;
    height: 300px;
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;

  & > h1 {
    color: ${$.color.white};
    font-size: 50px;
    font-family: Lato Bold;
  }

  ${$.device.tablet} {
    & > h1 {
      font-size: 45px;
    }
  }

  ${$.device.mobile} {
    & > h1 {
      font-size: 40px;
    }
  }
`;

const Banner = () => (
  <Section>
    <Container>
      <ImageContainer>
        <StaticImage src="../../assets/images/careers/cover-image.jpg" alt="" />
        <ImageOverlay>
          <h1>Career</h1>
        </ImageOverlay>
      </ImageContainer>
      <Fade bottom distance="100px">
        <p>
          Prestressed Spun Concrete Poles offer a host of advantages which make
          them a better choice over wooden and steel as well as other types of
          concrete poles. Strong and maintenance-free, the poles are fully
          accepted and meets the stringent standards set by National Electricity
          Board of Malaysia, Telecoms Malaysia and Malaysia Railway.
        </p>
      </Fade>
    </Container>
  </Section>
);

export default Banner;
