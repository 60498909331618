import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import Chevron from '../../assets/icons/chevron-right-black.svg';
import Upload from '../../assets/icons/upload.svg';

const inputStyles = css`
  background-color: #f5f5f5;
  font-family: 'Lato Regular';
  font-size: 18px;
  padding: ${$.layout().padding2}px;
  transition: box-shadow 0.3s ${$.easingFn.standard},
    background-color 0.3s ${$.easingFn.standard};
  border-radius: ${$.border().radius3}px;
  box-sizing: border-box;
  border: 2px solid #f5f5f5;
  box-shadow: none !important;
  outline: none;

  &:focus {
    background-color: ${$.color.white};
  }

  &:hover {
    box-shadow: ${$.dropShadow.normal} !important;
  }

  &::placeholder {
    opacity: 0.4;
  }
`;

const Label = styled.label`
  font-family: 'Lato Bold';
  font-size: 20px;
  margin-bottom: ${$.layout().margin2}px;

  &[for='resume'] {
    ${inputStyles}
    color: ${({ resume }) =>
      resume ? $.color.gray1 : 'rgba(102, 102, 102, 0.6)'};
  }

  &[for='cert'] {
    ${inputStyles}
    color: ${({ cert }) => (cert ? $.color.gray1 : 'rgba(102, 102, 102, 0.6)')};
  }
`;

const Input = styled.input`
  ${inputStyles}

  &#resume, &#cert {
    display: none;
  }
`;

const TextArea = styled.textarea`
  resize: vertical;
  min-height: 100px;
  ${inputStyles}
  line-height: 1.3em;
`;

const DropdownButton = styled.div`
  ${inputStyles}
  user-select: none;
  color: ${({ selectedItem }) =>
    selectedItem ? $.color.gray1 : 'rgba(102, 102, 102, 0.6)'};
  cursor: pointer;
  position: relative;
`;

const DropdownUl = styled.ul`
  font-family: Lato Regular;
`;

const DropdownLi = styled.li`
  cursor: pointer;
  font-family: Lato Regular;
  background-color: ${({ currentlySelected }) =>
    currentlySelected ? 'rgba(102, 102, 102, 0.2)' : $.color.white};
`;

const DropdownChevronContainer = styled.div`
  position: absolute;
  width: 15px;
  top: 18px;
  right: 10px;
  transform: rotate(${({ isOpen }) => (isOpen ? 270 : 90)}deg);
  transform-origin: 3px center;
  transition: transform 0.3s ${$.easingFn.standard};
`;

const DropdownChevron = ({ isOpen }) => (
  <DropdownChevronContainer isOpen={isOpen}>
    <Chevron />
  </DropdownChevronContainer>
);

DropdownChevron.propTypes = {
  isOpen: PropTypes.bool,
};

DropdownChevron.defaultProps = {
  isOpen: false,
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &.gender,
  &.maritalStatus,
  &.resume,
  &.cert {
    position: relative;
    color: ${$.color.gray1};

    & > ${DropdownUl} {
      background-color: ${$.color.white};
      list-style-type: none;
      border-radius: ${$.border().radius3}px;
      box-shadow: ${$.dropShadow.normal};
      overflow: hidden;
      position: absolute;
      top: 100px;
      left: 0px;
      right: 0px;
      z-index: 2;

      & > ${DropdownLi} {
        margin-left: -40px;
        padding: ${$.layout().padding2}px ${$.layout().padding2}px;
      }
    }
  }
`;

const UploadIconContainer = styled.div`
  position: absolute;
  width: 20px;
  top: 46px;
  right: 15px;

  & > svg {
    width: 100%;
  }
`;

const UploadIcon = () => (
  <UploadIconContainer>
    <Upload />
  </UploadIconContainer>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: ${({ isLoading }) => (isLoading ? 'auto' : 'none')};
  opacity: ${({ isLoading }) => (isLoading ? '1' : '0')};
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;

  & > svg {
    height: 50px;
    width: 50px;
    animation: ${rotate} 2s linear infinite;
  }
`;

export {
  Label,
  Input,
  TextArea,
  DropdownButton,
  DropdownUl,
  DropdownLi,
  DropdownChevron,
  InputContainer,
  LoaderContainer,
  UploadIcon,
};

export default {};
