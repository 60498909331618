import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import {
  Label,
  Input,
  TextArea,
  InputContainer,
  LoaderContainer,
  UploadIcon,
} from './formElements';
import Loader from '../../assets/icons/loader.svg';
import DropdownSelect from './DropdownSelect';

const Section = styled.section`
  color: ${$.color.base};
  margin-bottom: ${$.layout().margin5}px;
`;

const Container = styled.div`
  position: relative;
  color: ${$.color.base};
`;

const FormContainer = styled.form`
  padding: 0 ${$.layout().padding1}px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'name gender'
    'email maritalStatus'
    'address address'
    'homeTel mobileTel'
    'noticePeriod noticePeriod'
    'resume cert'
    'currentSalary expectedSalary'
    'submitButton submitButton';
  grid-gap: ${$.layout().margin2}px;
  margin-bottom: ${$.layout().margin3}px;

  ${$.device.mobile} {
    grid-template-areas:
      'name name'
      'gender gender'
      'email email'
      'maritalStatus maritalStatus'
      'address address'
      'homeTel homeTel'
      'mobileTel mobileTel'
      'noticePeriod noticePeriod'
      'resume resume'
      'cert cert'
      'currentSalary currentSalary'
      'expectedSalary expectedSalary'
      'submitButton submitButton';
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  grid-area: submitButton;
  color: ${$.color.white};
`;

const SubmitButton = styled.div`
  font-family: Lato Bold;
  background-color: ${$.color.red};
  padding: 8px 16px;
  border: 2px solid transparent;
  border-radius: 999px;
  min-width: 150px;
  transition: all 0.3s ease;
  margin-top: ${$.layout().margin1}px;
  text-align: center;

  &:hover {
    cursor: pointer;
    border: 2px solid ${$.color.red};
    color: ${$.color.red};
    background-color: ${$.color.white};
    & > svg {
      fill: ${$.color.red};
    }
  }

  ${$.device.mobile} {
    margin-top: ${$.layout().margin1}px;
    padding: 12px;
    flex: 1;
  }
`;

const ThanksContainer = styled.div`
  font-family: Lato Bold;
  font-size: 34px;
  text-align: center;

  ${$.device.mobile} {
    font-size: 30px;
  }
`;

const ThanksSubtitle = styled.div`
  margin-top: ${$.layout().margin2}px;
  font-family: Lato Regular;
  font-size: 16px;
`;

const genderItems = ['Male', 'Female'];
const maritalStatusItems = ['Single', 'Married', 'Widowed', 'Divorced'];

const Form = () => {
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [address, setAddress] = useState('');
  const [homeTel, setHomeTel] = useState('');
  const [mobileTel, setMobileTel] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [resume, setResume] = useState('');
  const [resumeDisplayName, setResumeDisplayName] = useState('');
  const [cert, setCert] = useState('');
  const [certDisplayName, setCertDisplayName] = useState('');
  const [currentSalary, setCurrentSalary] = useState('');
  const [expectedSalary, setExpectedSalary] = useState('');
  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);

  const upload = (file) => {
    const formData = new FormData();
    const fileName = `${Date.now()}.${file.type.split('/')[1].toLowerCase()}`;

    formData.append('file', file);

    return new Promise((resolve) => {
      axios({
        method: 'POST',
        url: 'https://2z5ksqo7n6.execute-api.ap-southeast-1.amazonaws.com/prod/presigned-url',
        data: JSON.stringify({
          name: fileName,
          type: file.type,
        }),
      })
        .then(({ data: { url } }) => {
          if (url === '') {
            resolve({ ok: false, data: fileName });
            return;
          }
          axios
            .put(url, file, {
              headers: {
                'Content-Type': file.type,
              },
            })
            .then((data) => {
              console.log(data);
              resolve({ ok: true, data: data.config.url.split('?')[0] });
            })
            .catch((err) => {
              console.log(err);
              resolve({ ok: false, data: fileName });
            });
        })
        .catch((err) => {
          console.log(err);
          resolve({ ok: false, data: fileName });
        });
    });
  };

  const handleFormSubmit = () => {
    if (
      !(
        name &&
        email &&
        maritalStatus &&
        address &&
        homeTel &&
        mobileTel &&
        noticePeriod &&
        resume &&
        cert &&
        currentSalary &&
        expectedSalary
      )
    ) {
      // eslint-disable-next-line
      alert('Incomplete form, please check the missing fields.');
      return;
    }

    console.log({
      name,
      email,
      maritalStatus,
      address,
      homeTel,
      mobileTel,
      noticePeriod,
      resume,
      cert,
      currentSalary,
      expectedSalary,
    });

    setLoading(true);

    axios({
      method: 'POST',
      url: 'https://2z5ksqo7n6.execute-api.ap-southeast-1.amazonaws.com/prod/career',
      data: JSON.stringify({
        name,
        gender,
        email,
        maritalStatus,
        address,
        homeTel,
        mobileTel,
        noticePeriod,
        resume,
        cert,
        currentSalary,
        expectedSalary,
      }),
    })
      .then(() => {
        setLoading(false);
        setFormSent(true);
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.log(e);
      });
  };

  return (
    <Section>
      <Fade bottom distance="100px">
        <Container>
          {loading && (
            <LoaderContainer isLoading={loading}>
              <Loader />
            </LoaderContainer>
          )}
          {!formSent ? (
            <FormContainer>
              <InputContainer style={{ gridArea: 'name' }}>
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  onChange={({ target: { value } }) => setName(value)}
                  placeholder="John Doe"
                />
              </InputContainer>
              <InputContainer className="gender" style={{ gridArea: 'gender' }}>
                <DropdownSelect
                  items={genderItems}
                  label="Gender"
                  placeholderMessage="Gender"
                  selectedItem={gender}
                  handleSelectedItemChange={({ selectedItem }) => {
                    setGender(selectedItem);
                  }}
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'email' }}>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  onChange={({ target: { value } }) => setEmail(value)}
                  placeholder="john.smith@example.com"
                />
              </InputContainer>
              <InputContainer
                className="maritalStatus"
                style={{ gridArea: 'maritalStatus' }}
              >
                <DropdownSelect
                  items={maritalStatusItems}
                  label="Marital Status"
                  placeholderMessage="Marital Status"
                  selectedItem={maritalStatus}
                  handleSelectedItemChange={({ selectedItem }) => {
                    setMaritalStatus(selectedItem);
                  }}
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'address' }}>
                <Label htmlFor="address">Address</Label>
                <TextArea
                  id="address"
                  onChange={({ target: { value } }) => setAddress(value)}
                  placeholder="123, Jalan Cempaka, Taman Orkid"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'homeTel' }}>
                <Label htmlFor="phoneNumberHome">Phone Number (Home)</Label>
                <Input
                  id="phoneNumberHome"
                  onChange={({ target: { value } }) => setHomeTel(value)}
                  placeholder="05-1234567"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'mobileTel' }}>
                <Label htmlFor="phoneNumberMobile">Phone Number (Mobile)</Label>
                <Input
                  id="phoneNumberMobile"
                  onChange={({ target: { value } }) => setMobileTel(value)}
                  placeholder="016-12345678"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'noticePeriod' }}>
                <Label htmlFor="noticePeriod">Notice Period</Label>
                <Input
                  id="noticePeriod"
                  onChange={({ target: { value } }) => setNoticePeriod(value)}
                  placeholder="1 month"
                />
              </InputContainer>
              <InputContainer className="resume" style={{ gridArea: 'resume' }}>
                <Label>Resume</Label>
                <Label htmlFor="resume" resume={resume}>
                  {resumeDisplayName || 'Upload your resume'}
                </Label>
                <UploadIcon />
                <Input
                  id="resume"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  onChange={({ target: { files } }) => {
                    const file = files[0];
                    const fileSize = (file.size / (1024 * 1024)).toFixed(3);

                    if (fileSize >= 16) {
                      alert('File size exceeded 16MB!');
                    }

                    upload(file).then(({ ok, data }) => {
                      if (ok) {
                        setResume(data);
                        setResumeDisplayName(file.name);
                      } else {
                        setResumeDisplayName('Error uploading file');
                      }
                    });
                  }}
                  placeholder="1 month"
                />
              </InputContainer>
              <InputContainer className="cert" style={{ gridArea: 'cert' }}>
                <Label>Certificate (Highest Qualification)</Label>
                <Label htmlFor="cert" cert={cert}>
                  {certDisplayName || 'Upload your certificate'}
                </Label>
                <UploadIcon />
                <Input
                  id="cert"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  onChange={({ target: { files } }) => {
                    const file = files[0];
                    const fileSize = (file.size / (1024 * 1024)).toFixed(3);

                    if (fileSize >= 16) {
                      alert('File size exceeded 16MB!');
                    }

                    upload(file).then(({ ok, data }) => {
                      if (ok) {
                        setCert(data);
                        setCertDisplayName(file.name);
                      } else {
                        setCertDisplayName('Error uploading file');
                      }
                    });
                  }}
                  placeholder="1 month"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'currentSalary' }}>
                <Label htmlFor="currentSalary">Current Salary</Label>
                <Input
                  id="currentSalary"
                  onChange={({ target: { value } }) => setCurrentSalary(value)}
                  placeholder="1000"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'expectedSalary' }}>
                <Label htmlFor="expectedSalary">Expected Salary</Label>
                <Input
                  id="expectedSalary"
                  onChange={({ target: { value } }) => setExpectedSalary(value)}
                  placeholder="3000"
                />
              </InputContainer>
              <SubmitButtonContainer>
                <SubmitButton onClick={handleFormSubmit}>
                  Submit enquiry form
                </SubmitButton>
              </SubmitButtonContainer>
            </FormContainer>
          ) : (
            <ThanksContainer>
              Thanks for contacting us!
              <ThanksSubtitle>
                We will try to get back to you in 3 working days.
              </ThanksSubtitle>
            </ThanksContainer>
          )}
        </Container>
      </Fade>
    </Section>
  );
};

export default Form;
