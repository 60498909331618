import React from 'react';
import { useSelect } from 'downshift';
import PropTypes from 'prop-types';

import {
  Label,
  DropdownButton,
  DropdownUl,
  DropdownLi,
  DropdownChevron,
} from './formElements';

const DropdownSelect = ({
  items,
  label,
  placeholderMessage,
  selectedItem,
  handleSelectedItemChange,
}) => {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    selectedItem,
    onSelectedItemChange: handleSelectedItemChange,
  });
  return (
    <>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <Label {...getLabelProps()}>{label}</Label>
      <DropdownButton selectedItem={selectedItem} {...getToggleButtonProps()}>
        {selectedItem || placeholderMessage}
        <DropdownChevron isOpen={isOpen} />
      </DropdownButton>
      <DropdownUl {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <DropdownLi
              key={`${item}-${index}`}
              currentlySelected={highlightedIndex === index}
              {...getItemProps({ item, index })}
            >
              {item}
            </DropdownLi>
          ))}
      </DropdownUl>
    </>
  );
};

DropdownSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  placeholderMessage: PropTypes.string,
  selectedItem: PropTypes.string,
  handleSelectedItemChange: PropTypes.func,
};

DropdownSelect.defaultProps = {
  items: [],
  label: 'Label',
  placeholderMessage: '',
  selectedItem: '',
  handleSelectedItemChange: () => {},
};

export default DropdownSelect;
